









import { Component, Vue } from 'vue-property-decorator'
import AuthService from '@/services/auth_service'
import { GenericError, PermissionsError } from '@/services/error_service'

@Component
export default class VerifyEmail extends Vue {
  protected authService: AuthService = new AuthService();
  protected token = '';
  protected userId = '';
  protected error = '';

  created () {
    this.userId = this.$route.query.id as string
    this.token = this.$route.query.token as string
    if (this.userId && this.token) {
      this.verify()
    }
  }

  public async verify (): Promise<void> {
    let loader = this.$loading.show(
      { opacity: 1 },
      { before: this.$createElement('h1', 'Verifying Email...') }
    )
    try {
      const user = await this.authService.verifyRegistration(
        this.token,
        this.userId
      )
      this.error = ''
      loader.hide()
      loader = this.$loading.show(
        { opacity: 1 },
        { before: this.$createElement('h1', 'Email verified! Logging in...') }
      )
      setTimeout(() => {
        this.$router.push('/logged-in')
        loader.hide()
      }, 2000)
    } catch (err) {
      loader.hide()
      if (err instanceof PermissionsError) {
        this.error = err.message
      } else {
        GenericError.popup(err.message)
      }
    }
  }
}
